/* Footer.css */

.footer {
  background-color:  #c3a58f;
  color: #eaeaea;
  padding: 20px;
  text-align: center;
  font-size: 0.9em;
}

.footer p {
  margin: 0;
}
