.about-section {
  display: flex;
  flex-direction: column; 
  align-items: center;
  max-width: 50%;
  margin: 0 auto;
  padding: 10px;
  padding-top: 80px;
  margin-bottom: 150px;
  opacity: 0; 
  transform: translateY(20px); 
  transition: opacity 1.0s ease-out, transform 1.0s ease-out;
}
.about-section.fade-in {
  opacity: 1; 
  transform: translateY(0); 
}
.section-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.text-section{
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 40px;
}

.intro-text {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 20px;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.summary {
  margin-bottom: 20px;
}

.job-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.description {
  font-size: 0.8em;
  color: #666;
  
  
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(3, auto); 
  gap: 30px 20px; 
  justify-content: center; 
  color: #333;
}

.contact-row {
  display: flex;
  align-items: center;
  width: 100%; 
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.contact-row:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.contact-icon {
  font-size: 1.2em;
  color: #007bff;
  margin-right: 8px;
}

.contact-label {
  font-weight: bold;
  margin-right: 5px;
}


.links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.link-icon {
  font-size: 1em;
  color: #007bff;
  text-decoration: none;
  display: inline; 
  font-size: inherit; 
  align-items: center;
  gap: 5px;
  transition: color 0.3s ease;
}

.link-icon:hover {
  color: #0056b3;
}

.link-icon i {
  font-size: 1.2em;
}


.gradient-divider {
  height: 4px;
  width: 40%;
  background: linear-gradient(to right, #4b4b4b, #ffc107);
  margin-bottom: 50px;
  max-width: 110px;
}
