.resume-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.section-description {
  
  color: #555;
  margin-bottom: 30px;
  font-size: 1.1em;
}

.resume-columns {
  display: flex;
  gap: 60px;
}

.resume-column {
  flex: 1;
}

.column-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.timeline {
  position: relative;
  padding: 10px 0;
  border-left: 3px solid #ffc107;
  max-width: 1200px;
}

.timeline-item {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 5px;
  width: 15px;
  height: 15px;
  background-color: #ffc107;
  border-radius: 50%;
  border: 3px solid #fff;
}

.timeline-date {
  font-size: 0.9em;
  color: #999;
  margin-bottom: 5px;
}

.timeline-content {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-title {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.timeline-organization {
  font-size: 1em;
  color: #555;
  margin: 5px 0;
}

.timeline-description {
  font-size: 0.9em;
  color: #666;
  margin: 0;
  white-space: pre-line;
}
