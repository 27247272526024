/* App.css */

.App {
  font-family: Arial, sans-serif;
}

.navbar {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

@media (max-width: 1200px) {
  .navbar {
    display: none; 
  }
}


.nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #333;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px;
  overflow: hidden;
}

.nav-icon:hover,
.nav-icon.active {
  width: 120px;
  height: 50px;
  border-radius: 15px;
  justify-content: flex-start;
  padding-left: 15px;
}

.nav-icon:hover {
  background-color: #6c757d;
  color: #fff;
}

.nav-icon.active {
  background-color: #4b4b4b;
  color: #ffffff;
}

.icon {
  font-size: 1em;
  color: inherit;
}

.nav-text {
  position: absolute;
  left: 50px;
  opacity: 0;
  font-size: 0.6em;
  color: #fff;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.nav-icon:hover .nav-text,
.nav-icon.active .nav-text {
  opacity: 1;
}

.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  scroll-behavior: smooth;
  transition: opacity 0.5s ease-in-out;
}



