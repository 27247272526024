.projects-section {
    text-align: center;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .categories {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .category-btn {
    padding: 10px 20px;
    font-size: 1em;
    background: #f4f4f4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .category-btn.active,
  .category-btn:hover {
    background: #ffc107;
    color: white;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .project-card {
    background: #fff;
    border-radius: 10px;
    
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  }
  
  .project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-info {
    padding: 20px;
    text-align: left;
  }
  
  .project-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .project-description {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px;
  }
  
  .project-tools {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tool-badge {
    background: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
  }
  