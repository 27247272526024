
.header {
  height: 100vh;
  background-size: cover;
  background-position: right; 
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  color: #ffffff;
  
}

.left-section{
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}


.profile {
  text-align: center;
  margin-right: 70px;
  margin-left: 90px;
  
}

.profile h1 {
  font-size: 3.0em;
  color: #ffffff; 
}

.summary-section {
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
}

.summary {
  font-size: 1.4em;
  color: #ffffff; 
  line-height: 1.6;
  margin-bottom: 15px;
}

.read-more {
  color: #ffc107; 
  font-size: 1em;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s;
}

.read-more:hover {
  color: #ffecb3;
}

.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-links a {
  color: #ffffff;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ffc107;
}

/* 작은 화면에서 텍스트가 이미지 위로 이동 */
@media (max-width: 768px) {
  .header {
    padding-left: 20px;
    flex-direction: column;
    background-position: center;
    background-size: cover; 
  }

  .profile {
    margin: 0;
    margin-bottom: 20px;
  }

  .profile h1 {
    font-size: 2em;
    color: #ffffff;
  }

  .summary-section {
    max-width: 90%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8); 
    border-radius: 8px;
  }

  .summary {
    font-size: 1em;
    color: #ffffff;
  }
}

@media (max-width: 480px) {
  .profile h1 {
    font-size: 1.5em;
  }

  .summary-section {
    max-width: 100%;
    padding: 10px;
  }

  .summary {
    font-size: 0.9em;
  }
}

/* modal style */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: #ffffff !important; 
  color: #333333;
  padding: 30px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 20;
  opacity: 1;
}

.modal-content h2 {
  font-size: 1.8em;
  color: #333333;
  margin-bottom: 10px;
}

.modal-content p {
  font-size: 1em;
  color: #333333;
  line-height: 1.6;
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  color: #ffffff;
  background: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background: #0056b3;
}
