.skills-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.section-description {
  color: #666;
  margin-bottom: 20px;
  font-size: 1.1em;
}

.gradient-divider {
  height: 4px;
  width: 40%;
  background: linear-gradient(to right, #4b4b4b, #ffc107);
  margin-bottom: 30px;
}

.skills-table {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 900px;
}

.skills-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  gap: 30px;
}

.category {
  flex: 1;
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  color: #333;
  white-space: nowrap;
}

.skills-list {
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: flex-start;
}

.skills-list i,
.skills-list .custom-icon {
  font-size: 2em;
  transition: transform 0.3s ease;
}

.skills-list i:hover,
.skills-list .custom-icon:hover {
  transform: scale(1.1);
}

.skills-section-table{
  display: flex;
  gap: 40px;
  flex-direction: column; 
  align-items: center;
}